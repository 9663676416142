import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import { Applications } from './dashboard/Applications';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

export const Downloads = (props) => {

    const classes = useStyles();
    const [open, setOpen] = useState(true);

    return (
        <>
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Downloads
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableBody>
                                <TableRow className={classes.root}>
                                    <TableCell>Unity 3D Client</TableCell>
                                    <TableCell>1.1.0</TableCell>
                                    <TableCell>
                                        <IconButton color="primary" aria-label="Settings" component="span" onClick={() => window.open('/download/get/Neuron-Unity3D-1.1.0.zip', '_blank')}>
                                            <GetAppIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                <TableRow className={classes.root}>
                                    <TableCell>Server</TableCell>
                                    <TableCell>1.1.0</TableCell>
                                    <TableCell>
                                        Contact us.
                                </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                </div>
            </Container>
        </>
    );
}
