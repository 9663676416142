import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const codeStyle = vs2015;

export const Property = (props) => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="lg">
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    Property
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Properties are hastables that sync values between server and clients. Can be changed by the server and the client side. Rooms, players and objects have properties.
                    </Box>
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Changing room property from Unity client :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'NeuronEngineClient.Room.Properties["MyCustomProperty"] = 1.5f;'
                    }
                </SyntaxHighlighter>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Changing player property from Unity client :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'NeuronEngineClient.Room.Players[playerId].Properties["MyCustomProperty"] = 1.5f;'
                    }
                </SyntaxHighlighter>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Changing object property from Unity client :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'NeuronEngineClient.Room.Objects[objectId].GetComponent<NetworkObject>().Properties["MyCustomProperty"] = 1.5f;'
                    }
                </SyntaxHighlighter>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Don't forget changing property could rejected by the server, Code 0 is meaning of request is successful :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'var code = await NeuronEngineClient.Room.ChangePropertyAsync("MyCustomProperty", 1.5f);'
                    }
                </SyntaxHighlighter>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'StartCoroutine(NeuronEngineClient.Room.ChangeProperty("MyCustomProperty", 1.5f, (code) => { }));'
                    }
                </SyntaxHighlighter>
            </div>
        </Container>);
}
