import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});


export const Home = (props) => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="lg">
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    Neuron Networking Engine
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Neuron Engine is a library that allows you to easily develop the network infrastructure of your multiplayer games with OOP concepts without complex networking operations. It has client library for the Unity and will have Unreal Engine library in the future. Supports TCP, UDP and RUDP protocols. All components are developed with the .NET Standard 2.0 and can be easily operated in almost any environment.
                </Typography>
                <br /><br />
                <Typography variant="h4" gutterBottom>
                    Cloud
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You can use Neuron Engine Cloud for free with only some limitations. Just create an account and get your application id for client. 
                </Typography>
            </div>
        </Container>);
}
