import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Alert from '@material-ui/lab/Alert';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const codeStyle = vs2015;

export const IMessage = (props) => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="lg">
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    IMessage
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        All sending and receiving messages are done through IMessage interface. Inherit your message from IMessage and set to MessageId as a unique value.
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'public class ChatMessage : IMessage \n' +
                        '{ \n' +
                        '	public short MessageId => 1; // Unique message id, should be same on server and client \n' +
                        '	public string Message { get; set; } \n' +
                        '}'
                    }
                </SyntaxHighlighter>

                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Message send methods always accept IMessage. Sends chat message to all players on room 0 :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'gameServer.Rooms[0].SendAllReliable(new ChatMessage { Message = "Test message." });'
                    }
                </SyntaxHighlighter>
                <Alert severity="warning">Dont use -32768 to -32.268 as MessageId. These values are reserved for system messages.</Alert>

                <Box p={1}/>
                <Typography variant="h5" gutterBottom>
                    Message Serialization
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        These types are valid for IMessage Serialization :
                        <Box style={{ marginLeft: "50px" }}>Byte</Box>
                        <Box style={{ marginLeft: "50px" }}>Bool</Box>
                        <Box style={{ marginLeft: "50px" }}>Short</Box>
                        <Box style={{ marginLeft: "50px" }}>Int</Box>
                        <Box style={{ marginLeft: "50px" }}>Long</Box>
                        <Box style={{ marginLeft: "50px" }}>Float</Box>
                        <Box style={{ marginLeft: "50px" }}>Double</Box>
                        <Box style={{ marginLeft: "50px" }}>String</Box>
                        <Box style={{ marginLeft: "50px" }}>Guid</Box>
                        <Box style={{ marginLeft: "50px" }}>Enum</Box >
                        <Box style={{ marginLeft: "50px" }}>Hashtable</Box >
                        <Box style={{ marginLeft: "50px" }}>IMessage</Box >
                        <Box style={{ marginLeft: "50px" }}>Object of all types above</Box >
                        <Box style={{ marginLeft: "50px" }}>Array of all types above</Box >
                        <Box style={{ marginLeft: "50px" }}>List of all types above</Box >
                    </Box>
                </Typography>
            </div>
        </Container>);
}
