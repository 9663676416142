import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Login } from './components/Login'; 
import { Register } from './components/Register'; 
import { ForgotPassword } from './components/ForgotPassword';
import { ResetPassword } from './components/ResetPassword'; 
import { Documentation } from './components/Documentation'; 
import { Dashboard } from './components/Dashboard';
import { Downloads } from './components/Downloads';
import './custom.css'
import ContextProvider from './components/ContextProvider';

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
          <ContextProvider>
              <Layout>
                  <Route exact path='/' component={Home} />
                  <Route path='/login' component={Login} />
                  <Route path='/register' component={Register} />
                  <Route path='/forgotpassword' component={ForgotPassword} />
                  <Route path='/resetpassword/:id' component={ResetPassword} />
                  <Route path='/downloads' component={Downloads} />
                  <Route path='/documentation' component={Documentation} />
                  <Route path='/dashboard' component={Dashboard} />
              </Layout>
          </ContextProvider>
    );
  }
}
