import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, TextField, Button, FormControlLabel, Checkbox, Container, Link, FormControl } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Context } from './ContextProvider';
import Network from './Network';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const ForgotPassword = (props) => {
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");

    const [message, setMessage] = useState("");

    const classes = useStyles();
    const history = useHistory();

    const forgotPassword = () => {

        setMessage("");

        if (email === "")
            return;

        setLoading(true);

        var data = {
            email: email,
        }

        Network.post('/api/user/forgotpassword', data)
            .then(response => {
                setMessage("Reset password e-mail has been sent, don't forget to check your spams.")
                setLoading(false);
            })
            .catch(response => {
                setLoading(false);
                setMessage("Unexpected error.");
            });
    }
 
    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                <FormControl fullWidth component="fieldset" className={classes.formControl}>
                    <TextField
                        required
                        fullWidth
                        autoFocus
                        variant="outlined"
                        margin="normal"
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                    {message}
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.submit}
                        onClick={forgotPassword}
                    >
                        Send
                     </Button>
                    <Grid container>
                    </Grid>
                </FormControl>
            </div>
        </Container>
    );
}
