import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Server } from './documentation/v100/Server';
import { ClientUnity } from './documentation/v100/ClientUnity';
import { IMessage } from './documentation/v100/IMessage';
import { Property } from './documentation/v100/Property';
import { Cluster } from './documentation/v100/Cluster';
import { Route } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function Introduction(props) {
    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom>
                Introduction
                </Typography>
            <Typography variant="body1" gutterBottom>
                Once you understand a few concepts such as IMessage and Property, it is quite easy to set up a multiplayer infrastructure with Neuron Engine. In the simplest form, you almost don't even need to write code.
            </Typography>
        </React.Fragment>
    );
}

export const Documentation = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [version, setVersion] = useState("1.0.0");

    const [commonOpen, setCommonOpen] = useState(false);
    const [clientOpen, setClientOpen] = useState(false);
    const [serverOpen, setServerOpen] = useState(false);
    const [selected, setSelected] = useState(false);

    const handleCommonClick = () => {
        setCommonOpen(!commonOpen);
    };

    const handleClientClick = () => {
        setClientOpen(!clientOpen);
    };

    const handleMenuClick = (key) => {
        setSelected(key);

        switch (key) {
            case "introduction":
                history.push('/documentation/1.0.0')
                break;

            case "server":
                setServerOpen(!serverOpen);
                history.push('/documentation/1.0.0/server')
                break;

            case "cluster":
                history.push('/documentation/1.0.0/server/cluster')
                break;

            case "clientunity":
                history.push('/documentation/1.0.0/client/unity')
                break;

            case "imessage":
                history.push('/documentation/1.0.0/imessage')
                break;

            case "property":
                history.push('/documentation/1.0.0/property')
                break;
        }
    };

    return (
        <div>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={2}>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Documentation
                            </ListSubheader>
                        }
                        className={classes.root}>

                        <ListItem button selected={selected === "introduction"} onClick={() => handleMenuClick("introduction")}>
                            <ListItemText primary="Introduction" />
                        </ListItem>

                        <ListItem button selected={selected === "server"} onClick={() => handleMenuClick("server")}>
                            <ListItemText primary="Server" />
                            {serverOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={serverOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button selected={selected === "cluster"} className={classes.nested}  onClick={() => handleMenuClick("cluster")}>
                                    <ListItemText primary="Cluster" />
                                </ListItem>
                            </List>
                        </Collapse>


                        <ListItem button onClick={handleClientClick}>
                            <ListItemText primary="Client" />
                            {clientOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={clientOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button selected={selected === "clientunity"} className={classes.nested} onClick={() => handleMenuClick("clientunity")}>
                                    <ListItemText primary="Unity" />
                                </ListItem>
                            </List>
                        </Collapse>

                        <ListItem button onClick={handleCommonClick}>
                            <ListItemText primary="Common" />
                            {commonOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={commonOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem button selected={selected === "imessage"} className={classes.nested} onClick={() => handleMenuClick("imessage")}>
                                    <ListItemText primary="IMessage" />
                                </ListItem>
                            </List>
                            <List component="div" disablePadding>
                                <ListItem button selected={selected === "property"} className={classes.nested} onClick={() => handleMenuClick("property")}>
                                    <ListItemText primary="Property" />
                                </ListItem>
                            </List>
                        </Collapse>

                    </List>
                </Grid>

                <Grid item xs={12} sm={8}>
                    <Route exact path='/documentation/1.0.0' component={Introduction} />
                    <Route exact path='/documentation/1.0.0/server' component={Server} />
                    <Route path='/documentation/1.0.0/server/cluster' component={Cluster} />
                    <Route path='/documentation/1.0.0/client/unity' component={ClientUnity} />
                    <Route path='/documentation/1.0.0/imessage' component={IMessage} />
                    <Route path='/documentation/1.0.0/property' component={Property} />
                </Grid>
            </Grid>

      </div>
    );
}
