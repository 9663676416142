import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box, Chip } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const codeStyle = vs2015;

export const ClientUnity = (props) => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="lg">
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    Unity Client
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        All client operations are on <Chip label="NeuronEngineClient" /> static class.
                    </Box>
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Best use case is creating MonoBehaviour class with <Chip label="DontDestroyOnLoad" />.
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'public class MultiplayerManager : MonoBehaviour \n' +
                        '{ \n' +
                        '    private void Awake() \n' +
                        '    { \n' +
                        '        DontDestroyOnLoad(this); \n' +
                        '    } \n' +
                        '	 \n' +
                        '    void Start() \n' +
                        '    { \n' +
                        '        NeuronEngineClient.OnConnected = () => \n' +
                        '        { \n' +
                        '            Debug.Log("Connected"); \n' +
                        '        }; \n' +
                        ' \n' +
                        '        // Server HTTP address and player \n' +
                        '        StartCoroutine(NeuronEngineClient.Connect("http://127.0.0.1:85", new Player { Name = "TestPlayer" })); \n' +
                        '        // Or connect to the Neuron Cloud with StartCoroutine(NeuronEngineClient.ConnectCloud("appId", new Player { Name = "Player1" })); \n' +
                        '    } \n' +
                        '}'
                    }
                </SyntaxHighlighter>
                <Alert severity="warning">There is no any event used on Neuron Engine. All of the methods like 'OnConnected' are delegates.</Alert>

                <Box p={1}/>
                <Typography variant="h5" gutterBottom>
                    Create Room
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        You can create rooms with Unity client. Player joins automatically to the created room.
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'var roomProperties = new Property \n' +
                        '{ \n' +
                        '    { "playerCount", 5 }, \n' +
                        '    { "level", 10 } \n' +
                        '}; \n' +
                        ' \n' +
                        'StartCoroutine(NeuronEngineClient.CreateRoom(roomProperties));'
                    }
                </SyntaxHighlighter>

                <Box p={1} />
                <Typography variant="h5" gutterBottom>
                    Get Rooms
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'var filter = new Property \n' +
                        '{\n' +
                        '    { "playerCount", 5 }, \n' +
                        '    { "level", 10 } \n' +
                        '};\n' +
                        '\n' +
                        'StartCoroutine(NeuronEngineClient.GetRooms(rooms => { Debug.Log($"Room count : {rooms.Count}"); }, roomProperties: filter));'
                    }
                </SyntaxHighlighter>

                <Box p={1} />
                <Typography variant="h5" gutterBottom>
                    Join Room
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'StartCoroutine(NeuronEngineClient.JoinRoom(roomId));'
                    }
                </SyntaxHighlighter>

                <Box p={1} />
                <Typography variant="h5" gutterBottom>
                    Send a Message
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        '// Send message to the server \n' +
                        'NeuronEngineClient.Room.Send(MessageType.TCP, new ChatMessage { Message = "test" }); \n' +
                        ' \n' +
                        '// Sends a message to the server through the TCP channel 0 \n' +
                        'NeuronEngineClient.Room.Send(MessageType.TCP, (byte)0, new ChatMessage { Message = "test" }); \n' +
                        ' \n' +
                        '// But better use SendReliable or SendUnreliable, these are load balanced between channels \n' +
                        'NeuronEngineClient.Room.SendReliable(new ChatMessage { Message = "test" }); \n' +
                        ' \n' +
                        '// Sends message to only other players \n' +
                        'NeuronEngineClient.Room.SendReliable(MessageTarget.AllOthers, new ChatMessage { Message = "test" });'
                    }
                </SyntaxHighlighter>

                <Box p={1} />
                <Typography variant="h5" gutterBottom>
                    Spawn Object
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Spawning an object consists of two stages<br /><br />

                        <Box>- Spawn object by player.</Box>
                        <Box>- Create spawned object on other players.</Box><br />

                        When an object spawned, the object is created by locating in the Resources folder with the given id on other players. Of course you can easily override this behaivour.
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        '// Sphere is a prefab in the Resources folder \n' +
                        'var initiated = Instantiate(Resources.Load("Sphere") as GameObject); \n' +
                        'StartCoroutine(NeuronEngineClient.Room.Object.SpawnObject(initiated, \n' +
                        '                                           "Sphere", \n' +
                        '                                           new SyncSettings \n' +
                        '                                           { \n' +
                        '                                               SyncPosition = true // There is plenty of sync settings\n' +
                        '                                           }, \n' +
                        '                                           (spawnedObject) => { }));'
                    }
                </SyntaxHighlighter>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Other players must provide spawned object. By default, GetSpawnedObject searches object in the Resources folder.
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'NeuronEngineClient.Room.Object.GetSpawnedObject = (id, properties, position, rotation, scale) => \n' +
                        '{ \n' +
                        '    var initiated = Instantiate(Resources.Load("Sphere") as GameObject); \n' +
                        '    initiated.transform.position = position; \n' +
                        '    initiated.transform.rotation = rotation; \n' +
                        '    initiated.transform.localScale = scale; \n' +
                        ' \n' +
                        '    // Maybe change object with "properties" \n' +
                        ' \n' +
                        '    return initiated; \n' +
                        '};'
                    }
                </SyntaxHighlighter>
            </div>
        </Container>);
}
