import React, { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, Grid, TextField, Button, FormControlLabel, Checkbox, Container, Link, FormControl } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Context } from './ContextProvider';
import Network from './Network';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const ResetPassword = (props) => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [error, setError] = useState("");

    const classes = useStyles();
    const history = useHistory();

    const onResetPasswordClicked = () => {

        setPasswordError("");
        setConfirmPasswordError("");

        if (password === "")
            setPasswordError("Password is empty!");

        if (confirmPassword === "")
            setConfirmPasswordError("Password is empty!");

        if (password === "" || confirmPassword === "")
            return;

        if (password.length < 6) {
            setPasswordError("Passwords is too short!");
            return;
        }

        if (confirmPassword !== password) {
            setConfirmPasswordError("Passwords needs to match!");
            return;
        }

        setError("");
        setLoading(true);

        var data = {
            id: id,
            password: password
        }

        Network.post('/api/user/resetpassword', data)
            .then(response => {
                if (response.success) {
                    history.push('/login')
                } else {
                    setError("Failed");
                }

                setLoading(false);
            })
            .catch(response => {
                setLoading(false);
                setError("Unexpected error.");
            });
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <FormControl fullWidth component="fieldset" className={classes.formControl}>
                    {error !== "" && <Alert severity="error">{error}</Alert>}
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={passwordError !== ""}
                        helperText={passwordError}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        name="password"
                        label="Confirm Password"
                        type="password"
                        id="passwordconfirm"
                        autoComplete="current-password"
                        error={confirmPasswordError !== ""}
                        helperText={confirmPasswordError}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.submit}
                        onClick={onResetPasswordClicked}
                    >
                        Reset
                     </Button>
                </FormControl>
            </div>
        </Container>
    );
}
