import React, { useState, useEffect, createContext } from 'react';
export const Context = createContext();
const ContextProvider = (props) => {

    useEffect(() =>
    {
        var token = localStorage.getItem('token')
        var email = localStorage.getItem('email')

        if (token !== null) {
            setUser({
                authenticated: true,
                token: token,
                email: email
            });
        }
    }, [])

    const [user, setUser] = useState({
        authenticated: false,
        token: "",
        email: ""
    });

    return (
        <Context.Provider value={{ user: user, setUser: setUser }}>
            {props.children}
        </Context.Provider>
    );
}
export default ContextProvider;