import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles({
    root: {
        width: '100%',
    }
});

const codeStyle = vs2015;

export const Server = (props) => {
    const classes = useStyles();

    const gameServerCodeString =
        'public class GameServer : NeuronEngineGameServerBase \n' +
        '{ \n' +
        '	public GameServer(GameServerSettings settings) : base(settings) \n' +
        '	{ \n' +
        '	} \n' +
        '}';

    const startServerCodeString =

        'var settings = new GameServerSettings \n' +
        '{ \n' +
        '    ClusterId = "TestCluster", \n' +
        '    NodeId = "GameServer1", \n' +
        '    RoomServerEntry = new NeuronEngineServer \n' +
        '    { \n' +
        '        TcpEndPoints = new NeuronEngineEndPoint[] \n' +
        '        { \n' +
        '            new NeuronEngineEndPoint("127.0.0.1", 4000) \n' +
        '        }, \n' +
        '        UdpEndPoints = new NeuronEngineEndPoint[] \n' +
        '        { \n' +
        '            new NeuronEngineEndPoint("127.0.0.1", 5000) \n' +
        '        }, \n' +
        '        RUdpEndPoints = new NeuronEngineEndPoint[] \n' +
        '        { \n' +
        '            new NeuronEngineEndPoint("127.0.0.1", 6000) \n' +
        '        }, \n' +
        '    }, \n' +
        '    HttpPrefix = "http://127.0.0.1:85", \n' +
        '    MaxRoomCount  = 5 \n' +
        '}; \n' +
        '\n' +
        'var gameServer = new GameServer(settings); \n' +
        'gameServer.Start();';

    return (
        <Container component="main" maxWidth="lg">
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    Game Server
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Game server is contains game rooms. Clients connect to these rooms and mutually send and receive messages to each other.Each room is isolated from each other and has its own state.
                    </Box>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Settings
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        <Box style={{marginBottom: "8px"}}>ClusterId : Id of the cluster. Should be the same on every nodes on cluster. Name it as short as possible for reduce network load.</Box>
                        <Box style={{ marginBottom: "8px" }}>NodeId : If of the node. Should be the different on every nodes on cluster. Name it as short as possible for reduce network load.</Box>
                        <Box style={{ marginBottom: "8px" }}>RoomServerEntry : </Box>
                        <Box style={{ marginBottom: "8px" }}>StopRoomOnNoPlayersLeft : If true game room stopped after no players left on game room.</Box>
                        <Box style={{ marginBottom: "8px" }}>MaxRoomCount : Maximum room count.</Box>
                        <Box style={{ marginBottom: "8px" }}>MaxPlayerCountOnRoom : Maximum connected player count on each room.</Box>
                        <Box style={{ marginBottom: "8px" }}>HttpPrefix : Http.</Box>
                    </Box>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Start The Server
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        You should inherit your class from <Chip label="NeuronEngineGameServerBase" />
                    </Box>
                </Typography>
                <Box p={1}>
                    <SyntaxHighlighter language="csharp" style={codeStyle}>
                        {gameServerCodeString}
                    </SyntaxHighlighter>
                </Box>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Create settings and start the server.
                    </Box>
                </Typography>
                <Box p={1}>
                    <SyntaxHighlighter language="csharp" style={codeStyle}>
                        {startServerCodeString}
                    </SyntaxHighlighter>
                </Box>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        <Box style={{ marginBottom: "18px" }}>5 rooms are created with these settings on the server. These rooms have one channel for each port of TCP, UDP and RUDP and server has one HTTP listener for matchmaking operations.</Box>

                        <Box style={{ marginBottom: "8px" }}>Room-0 -> TCP - 127.0.0.1:4000, UDP - 127.0.0.1:5000, RUDP - 127.0.0.1:6000</Box>
                        <Box style={{ marginBottom: "8px" }}>Room-1 -> TCP - 127.0.0.1:4001, UDP - 127.0.0.1:5001, RUDP - 127.0.0.1:6001</Box>
                        <Box style={{ marginBottom: "8px" }}>Room-2 -> TCP - 127.0.0.1:4002, UDP - 127.0.0.1:5002, RUDP - 127.0.0.1:6002</Box>
                        <Box style={{ marginBottom: "8px" }}>Room-3 -> TCP - 127.0.0.1:4003, UDP - 127.0.0.1:5003, RUDP - 127.0.0.1:6003</Box>
                        <Box style={{ marginBottom: "8px" }}>Room-4 -> TCP - 127.0.0.1:4004, UDP - 127.0.0.1:5004, RUDP - 127.0.0.1:6004</Box>
                     </Box>
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Game Rooms
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Game rooms automatically created on start. You can access room operations on <Chip label="Room" /> dictionary.
                    </Box>
                </Typography>

                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Start first room :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {"gameServer.Rooms[0].Start();"}
                </SyntaxHighlighter>

                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Get all players on first room :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {"var playersOnFirstRoom = gameServer.Rooms[0].Players;"}
                </SyntaxHighlighter>

                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Send a message to the player whose id is 4 on first room :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {"gameServer.Rooms[0].SendReliable(4, new ChatMessage { Message = \"Hello!\" });"}
                </SyntaxHighlighter>

                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Get all created objects on first room :
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {"var allObjects = gameServer.Rooms[0].Objects;"}
                </SyntaxHighlighter>

                <Box p={1} />
                <Typography variant="h5" gutterBottom>
                    Callbacks
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        You can override bunch of methods like <Chip label="OnPlayerConnected" />. Callbacks starts with 'On' prefix.
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'public override void OnPlayerConnected(int gameRoomId, Player player) \n' +
                        '{ \n' +
                        '    Console.WriteLine($"Player : {player.Id}-{player.Name} Room : {gameRoomId}"); \n' +
                        '}'
                    }
                </SyntaxHighlighter>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        Some callback used for desicions.
                    </Box>
                </Typography>
                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'public override async Task<byte> OnObjectSpawning(int gameRoomId, short playerId, NetworkGameObject gameObject) \n' +
                        '{ \n' +
                        '	// Prevent object spawn if position x is negative. \n' +
                        '	if (gameObject.PositionX < 0) \n' +
                        '		return 1; // Return expect 0 on failed results\n' +
                        ' \n' +
                        '	return 0; // Return 0 if action is successful \n' +
                        '}'
                    }
                </SyntaxHighlighter>

            </div>
        </Container>);
}
