import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch, Typography, TextField, Button, FormControlLabel, Checkbox, Container, Link, FormControl } from '@material-ui/core';
import { Face, Fingerprint } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Network from './../Network';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    tableCell: {
        'width': '500px',
    }
}));

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function Row(props) {
    const { row } = props;
    const classes = useStyles();

    const handleChange = (event) => {
        Network.post('/api/application/changestatus', { id: row.id, isActive: event.target.checked })
            .then(response => {
                if (response.success) {
                } else {
                }
            });
    };

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>
                    <Switch
                        onChange={handleChange}
                        defaultChecked={row.isActive}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </TableCell>
                <TableCell>
                    <IconButton color="primary" aria-label="Settings" component="span" onClick={() => props.setApplication(row.id)}>
                        <EqualizerIcon />
                    </IconButton>
                    <IconButton color="primary" aria-label="Delete" component="span" onClick={() => props.setDeletedApplicationId(row.id)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export const Applications = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [apps, setApps] = useState([]);
    const [createOpen, setCreateOpen] = useState(false);
    const [creating, setCreating] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [deleting, setDeleting] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deletedApplicationId, setDeletedApplicationId] = useState("");


    const [application, setApplication] = useState(undefined);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [limitations, setLimitations] = useState(undefined);
    const [onlineCount, setOnlineCount] = useState(undefined);

    useEffect(() => {
        getAll()
    }, []);

    useEffect(() => {
        if (application === undefined)
            return;

        setDetailsOpen(true);

        Network.post('/api/application/limitations', { id: application })
            .then(response => {
                if (response.success) {
                    setLimitations(response.data);
                } else {

                }
            })
            .catch(response => {
            });

        Network.post('/api/application/onlinecount', { id: application })
            .then(response => {
                if (response.success) {
                    setOnlineCount(response.data);
                } else {

                }
            })
            .catch(response => {
            });

    }, [application]);

    const closeApplicationDetailDialog = () => {
        setDetailsOpen(false);
        setApplication(undefined);
        setLimitations(undefined);
        setOnlineCount(undefined);
    }

    useEffect(() => {
        if (deletedApplicationId === "")
            return;

        setDeleteOpen(true);
    }, [deletedApplicationId]);

    const closeDeleteDialog = () => {
        setDeleteOpen(false);
        setDeletedApplicationId("")
    }

    useEffect(() => {
        if (createOpen)
            setNameError("")

    }, [createOpen]);

    const handleCreate = () => {
        setNameError("")

        if (name.length < 1) {
            setNameError("Too short");
            return;
        }

        if (name.length > 40) {
            setNameError("Too long");
            return;
        }

        setCreating(true);

        Network.post('/api/application/create', { name : name })
            .then(response => {
                if (response.success) {
                    const newApps = [...apps, response.data.application];
                    setApps(newApps);
                    setCreateOpen(false);
                } else {
                    if (response.data.createApplicationStatus === 1)
                        setNameError("Only 1 application allowed on your account.");
                    else
                        setNameError("Unexpected error");
                }

                setCreating(false);
            })
            .catch(response => {
                setCreating(false);
            });
    };

    const getAll = () => {
        setLoading(true);

        Network.post('/api/application/getall')
            .then(response => {
                if (response.success) {
                    setApps(response.data);
                    setLoading(false);
                } else {

                }

                setLoading(false);
            })
            .catch(response => {

            });
    }

    const handleDelete = () => {
        setDeleting(true);

        Network.post('/api/application/delete', { id: deletedApplicationId })
            .then(response => {
                if (response.success) {
                    setDeleteOpen(false);
                    getAll()
                    setDeletedApplicationId("")
                } else {

                }

                setDeleting(false);
            })
            .catch(response => {
                setDeleting(false);
            });
    };

    return (
        <>
            <Container component="main" maxWidth="lg" align="center">
                {loading && <CircularProgress />}
                {!loading &&
                    <>
                        <TableContainer component={Paper}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell>Name</TableCell>
                                        <TableCell>Application Id</TableCell>
                                        <TableCell>Is Active</TableCell>
                                        <TableCell>
                                        <Button variant="contained" color="primary" onClick={() => setCreateOpen(true)}>
                                                Create
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {apps.map((row) => (
                                        <Row key={row.name} row={row} setDeletedApplicationId={setDeletedApplicationId} setApplication={setApplication} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
            </Container>

            <Dialog fullWidth onClose={() => setCreateOpen(false)} aria-labelledby="customized-dialog-title" open={createOpen}>
                <DialogTitle id="customized-dialog-title" onClose={() => setCreateOpen(false)}>
                    Create Application
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        required
                        fullWidth
                        autoFocus
                        variant="outlined"
                        margin="normal"
                        id="appname"
                        label="Application Name"
                        name="name"
                        autoComplete="name"
                        error={nameError !== ""}
                        helperText={nameError}
                        onChange={(e) => setName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCreate} color="primary" disabled={creating}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullWidth onClose={closeDeleteDialog} aria-labelledby="customized-dialog-title" open={deleteOpen}>
                <DialogTitle id="customized-dialog-title" onClose={closeDeleteDialog}>
                   Delete Application
                </DialogTitle>
                <DialogContent dividers>
                    Delete {deletedApplicationId}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleDelete} color="primary" disabled={deleting}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>



            <Dialog maxWidth="md" fullWidth onClose={closeApplicationDetailDialog} aria-labelledby="customized-dialog-title" open={detailsOpen}>
                <DialogTitle id="customized-dialog-title" onClose={closeApplicationDetailDialog}>
                    Application
                </DialogTitle>
                <DialogContent dividers>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                                <>
                                <Card>
                                    <CardContent>
                                        {limitations === undefined && <Typography align="center" variant="body2" color="textSecondary"> <CircularProgress align="center" /></Typography>}

                                        {limitations !== undefined &&
                                            <>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                Limitations
                                                </Typography>
                                                <Typography>
                                                    Maximum Player Count : {limitations.maxPlayer}
                                                </Typography>
                                                <Typography>
                                                    Maximum Player Count On Room : {limitations.maxPlayerOnRoom}
                                                </Typography>
                                                <Typography>
                                                    Maximum Object Count : {limitations.maxObjectCount}
                                                </Typography>
                                                <Typography>
                                                    Maximum Message Count Per Second On Room : {limitations.maxMessagePerSecondOnRoom}
                                                </Typography>
                                            </>
                                        }
                                    </CardContent>
                                </Card>
                                </>
                        </Grid>
                        <Grid item xs={6}>
                                <>
                                <Card>
                                    <CardContent>
                                        {(limitations === undefined || onlineCount === undefined) && <Typography align="center" variant="body2" color="textSecondary"> <CircularProgress align="center" /></Typography>}

                                        {onlineCount !== undefined && limitations !== undefined &&
                                            <>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Online Players
                                                </Typography>
                                                <LinearProgressWithLabel variant="determinate" value={Math.round((((onlineCount * 100)) / limitations.maxPlayer),)} />
                                                <Typography align="center" variant="body2" color="textSecondary">{`${onlineCount}/${limitations.maxPlayer}`}</Typography>
                                            </>
                                        }
                                    </CardContent>
                                </Card>
                                </>
                        </Grid>
                    </Grid>          

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={closeApplicationDetailDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            </>
    );
}
