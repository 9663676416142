import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { Context } from './ContextProvider';
import BottomNavigation from '@material-ui/core/BottomNavigation';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    footer: {
        bottom: "0px",
        top: 'auto',
    }
}));

export const Layout = (props) => {
    const { user, setUser } = useContext(Context);

    const [auth, setAuth] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const history = useHistory();
    const classes = useStyles();

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();

        localStorage.removeItem('token', null);
        localStorage.removeItem('email', null);

        setUser({
            authenticated: false,
            token: "",
            email: ""
        });

        history.push('/')
    };

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar>
                    <img src="neuron.png" alt="logo" className={classes.logo} onClick={() => history.push('/')} style={{ cursor : "pointer"}}/>
                    <Typography variant="h6" className={classes.title} style={{ marginLeft: "15px", cursor: "pointer" }} onClick={() => history.push('/')}>
                        <Box component="span" display={{ xs: 'none', sm: 'block' }}>Neuron Networking Engine <span style={{verticalAlign: "text-top", fontSize:"10px"}}>BETA</span></Box> 
                    </Typography>

                    <Button color="inherit" onClick={() => history.push('/downloads')}>Downloads</Button>
                    <Button color="inherit" onClick={() => history.push('/documentation/1.0.0')}>Documentation</Button>

                    {user.authenticated && (
                        <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={() => { handleClose(); history.push('/dashboard')}}>Dashboard</MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    )}

                    {!user.authenticated && (
                        <Button color="inherit" onClick={() => history.push('/login')}>Login</Button>
                    )}
                </Toolbar>
            </AppBar>
            <Toolbar />
            <div className={classes.toolbar} >
                {props.children}
            </div>
            <BottomNavigation>
                ggggh
            </BottomNavigation>

            <AppBar position="fixed" className={classes.footer} color="default">
                <Toolbar>
                    <Typography variant="caption" >
                        Copyright 2023 Neuron Engine, <a href="mailto:neuron.engine.info@gmail.com">neuron.engine.info@gmail.com</a>
                    </Typography>
                </Toolbar>
            </AppBar>
      </div>
    );
}
