import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import { Applications } from './dashboard/Applications';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const Dashboard = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Dashboard
                        </ListSubheader>
                        }
                        className={classes.root}
                    >
                        <ListItem button>
                            <ListItemText primary="Applications" />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Applications/>
                </Grid>
            </Grid>

      </div>
    );
}
