var Network = {};

Network.post = (url, data) => {

    var token = localStorage.getItem('token');

    const requestOptions = {
        method: 'POST',
        headers:
        {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(data)
    };

    return new Promise(function (resolve, reject) {
        fetch(url, requestOptions)
            .then(function (response) {
                if (response.status === 401) {
                    console.log("401")
                    //remove user
                    localStorage.removeItem('token', null);
                    localStorage.removeItem('email', null);

                    window.location = "/login";
                }
                else if (response.status !== 200) {
                    throw new Error(response)
                } 

                return response
            })
            .then(response => response.json())
            .then(response => {
                resolve(response);
            })
            .catch(function (response) {
                if (response.status === 401) {
                    console.log("401")
                    //remove user
                    localStorage.removeItem('token', null);
                    localStorage.removeItem('email', null);

                    window.location = "/login";
                }

                reject(response);
            });
    });
}

export default Network;