import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box, Chip } from '@material-ui/core';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

const codeStyle = vs2015;

export const Cluster = (props) => {
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="lg">
            <div className={classes.root}>
                <Typography variant="h4" gutterBottom>
                    Clustering
                </Typography>
                <Typography paragraph variant="body1">
                    <Box p={1}>
                        <Chip label="IClusterStateHandler" /> is responsible for all clustering operations for scalability. You can provide it to game server. On default <Chip label="MemoryClusterStateHandler" /> used and it store all state on memory so it is not scalable.
                        You should implement it and store the state in a place such as a database. In this way, all servers can access rooms that created on the all game servers.
                    </Box>
                </Typography>

                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'public class ClusterStateHandlerDatabase : IClusterStateHandler\n' +
                        '{ \n' +
                        ' \n' +
                        '	async Task RoomServerStarted(RoomServer roomServer)\n' +
                        '	{\n' +
                        '	    // Save room server to the db\n' +
                        '	}\n' +
                        '	 \n' +
                        '	async Task RoomServerStopped(Guid id)\n' +
                        '	{\n' +
                        '	    // Remove room server from db\n' +
                        '	}\n' +
                        '	 \n' +
                        '	async Task OnRoomPropertyChanged(Guid id, object key, object value) \n' +
                        '	{\n' +
                        '	    // Change room property on db\n' +
                        '	}\n' +
                        '	 \n' +
                        '	async Task OnRoomPropertyRemoved(Guid id, object key) \n' +
                        '	{\n' +
                        '	    // Remove room property from db\n' +
                        '	}\n' +
                        '	 \n' +
                        '	async Task<List<RoomServer>> GetRoomServers(Property roomProperties, int page, int count) \n' +
                        '	{\n' +
                        '	    // Return all room servers with specified room properties\n' +
                        '	}\n' +
                        '	 \n' +
                        '	async Task<RoomServer> GetRoomServer(Guid id) \n' +
                        '	{\n' +
                        '	    // Return room with specified id\n' +
                        '	}\n' +
                        '}'
                    }
                </SyntaxHighlighter>

                <SyntaxHighlighter language="csharp" style={codeStyle}>
                    {
                        'var gameServer = new GameServer(Settings, new ClusterStateHandlerDatabase());'
                    }
                </SyntaxHighlighter>
            </div>
        </Container>);
}
